export const SITESPECT_EXPERIMENT = {
	VARIANT: 'variant',
	CONTROL: 'control',
};

export const SITESPECT_EXPERIMENT_NAMES = {
	TRIAL_EXPERIMENT: 'trialExperiment',
	BAD_DEBT_VARIANT: 'badDebtVariant',
	TIERS_EXPERIMENT: 'tiersVariant',
};
