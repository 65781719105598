import { analyticsByRoute } from '../helpers/registration';
import { initatePageViewTracking, getPlatformData } from '../components/analytics/Snowplow';
import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import {
	ROUTE_ACTIVATE_VOUCHER,
	ROUTE_ACTIVATE_PLAN,
	ROUTE_ACTIVATED_PLAN,
} from '../constants/Routes';
import { addToGlobalContexts, removeFromGlobalContexts } from '@rtl_nl/rtl-snowplow';

export default function useInitTrackPageView(isLoading) {
	const location = useLocation();

	const data = analyticsByRoute[location.pathname.replace(/\/+$/, '')];

	const isVoucherPage = matchPath(location.pathname, ROUTE_ACTIVATE_VOUCHER);

	const isActivateOrFinalize = matchPath(location.pathname, {
		path: [ROUTE_ACTIVATE_PLAN, ROUTE_ACTIVATED_PLAN],
		exact: false,
		strict: false,
	});

	const locationToMap = isActivateOrFinalize?.path ?? location.pathname;

	useEffect(() => {
		const platformData = getPlatformData({ location: locationToMap });
		if (!platformData) return;

		addToGlobalContexts({
			rtlPlatform: { ...platformData },
		});
		return () => {
			removeFromGlobalContexts({ rtlPlatform: { ...platformData } });
		};
	}, [locationToMap]);

	useEffect(() => {
		if (data && !isVoucherPage && isLoading) initatePageViewTracking();
	}, [data, isLoading, isVoucherPage]);
}
